import { Component } from 'react';
import SideBar from 'components/SideBar/SideBar';
import NavBar from 'components/NavBar/NavBar';
import { UserContext } from 'contexts/UserContext';

export default class Wrapper extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props)
	}

	render() {
		
		return (
			<>
				<div className="main_wrapper_container">
					<SideBar />
					<div className="right_content">
						<NavBar user={this.context.user} />
						<div className={`content_container ${this.props.className}`}>
							{this.props.children}
						</div>
					</div>
				</div>
			</>
		)
	}
} 
