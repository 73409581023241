import React, { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
// import { FiUser, FiLock } from 'react-icons/fi';
import { withRouter, useParams } from 'react-router-dom';

import { AiFillSave, AiOutlineDelete, AiFillPrinter, AiOutlineEdit } from 'react-icons/ai';
import { infoData, getQuoteDetails, infoItems, infoTcs, infocustomeradd } from 'utils/utils';
import logo from 'assets/printlogo.png';
import './QuoteSurvey.scss';
import BarWave from 'react-cssfx-loading/lib/BarWave';
import PrintQuote from 'components/PrintQuote/PrintQuote';
import ReactToPrint from 'react-to-print';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class QuoteSurvey extends Component {
  static contextType = QuoteContext;

  constructor(props) {
    super(props);

    const questions = [
      'Would you be interested in any alternative quotations at different price points to make the project more suitable for you?',
      'Did you know we can offer 1 year interest free credit with a 25% deposit (subject to status) would this be something you would like details of?',
      'Are you still considering new flooring, or have your plans changed for now? Would you like us to hold this on file?',
      'Is there anything we could do to improve our service to better meet your needs?',
      'Was there anything specific about the quote or service that made you hesitate to proceed?',
      'If you are not going ahead, did you find a better price elsewhere based on the exact same products, or was budget a concern?',
    ];

    this.state = {
      disabled: true,
      loading: false,
      load: true,
      quoteid: props.params.quoteid,
      answers: [],
      questions,
    };
  }

  submitForm = async (e) => {
    this.setState({ ...this.state, disabled: true });
    let res = await this.context.submitSurvey(this.state.answers);
    this.setState({
      ...this.state,
      quote: { ...this.state.quote, quote_survey_submitted: new Date() },
    });
    window.scrollTo(0, 0);
  };

  loadQuoteForm = async (quoteid) => {
    if (!this.state.loading && this.state.load) {
      this.setState({ ...this.state, load: false, loading: true });
      let quote = await this.context.getQuote(quoteid);

      this.setState({ ...this.state, quote, loading: false });
    }
  };

  addAnswer = (question, answer) => {
    //loop the questions, loop answers to find a matching question
    const answers = this.state.questions.map((questionsQ) => {
      if (questionsQ == question) {
        return { question, answer }; // this question is the one being answered
      } else {
        const r = this.state.answers.filter((obj) => {
          return obj.question === questionsQ;
        }); // find the already given answer

        return r.length > 0 ? r[0] : { question: questionsQ, answer: '' };
      }
    });
    const countWithAnswer = answers.filter((obj) => obj.answer && obj.answer != '').length;
    console.log('countWithAnswer: ', countWithAnswer);
    this.setState({ ...this.state, answers, disabled: countWithAnswer > 0 ? false : true });
  };

  componentDidMount() {
    let quoteid = this.state.quoteid;
    this.loadQuoteForm(quoteid);
  }

  render() {
    let quote = this.state.quote;

    if (this.state.loading)
      return (
        <div className="pagePreloader">
          <span>
            <BarWave width="50px" height="20px" />
            <br />
            loading...
          </span>
        </div>
      );
    if (!quote || !quote._id)
      return (
        <div className="pagePreloader">
          <h3>Sorry, it looks like that link doesn't work. Please check and try again</h3>
        </div>
      );

    return (
      <div className="quote_details_page">
        {quote.quote_survey_submitted && (
          <div className="pagePreloader-half">
            <center>
              <h3>Thank you for your feedback!</h3>
            </center>
          </div>
        )}
        {!quote.quote_survey_submitted && (
          <div className="content_container">
            <div style={{ textAlign: 'right' }}>
              <img src={logo} style={{ width: '200px' }} />
            </div>
            <br />
            <br />
            Dear {`${quote.customer.fname} ${quote.customer.lname}`}
            <br />
            <br />
            Thank you for giving us the opportunity to quote for your job. At Carpet Options, we
            pride ourselves on delivering top-quality carpet installation services that exceed
            expectations. Our team is committed to making the process seamless and stress-free. With
            years of experience and a focus on precision and detail, we ensure that every project
            reflects the highest standards of craftsmanship.
            <br />
            <br />
            We noticed that you haven't yet accepted your quote, and we'd love the opportunity to
            answer any questions you may have. Customer satisfaction is at the heart of everything
            we do, which is why we offer personalized solutions tailored to meet your needs,
            competitive pricing, and a dedicated team ready to support you every step of the way.
            Let us help you turn your vision into reality and create a space you'll love for years
            to come.
            <br />
            <br />
            If you have 5 minutes to answer the follow 5 questions we would be very grateful.
            <br />
            <ul className="questions">
              {this.state.questions.map((question) => {
                return (
                  <li key={question.question}>
                    {question}
                    <br />
                    <textarea
                      onChange={(e) => {
                        this.addAnswer(question, e.target.value);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="approve_btns">
              <button onClick={this.submitForm} disabled={this.state.disabled}>
                Submit Answers
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withParams(QuoteSurvey);
