import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  toastOptions,
  sort,
  tillTimeString,
  getTotalInvoiceAmount,
  paymentterms,
  toNumber,
  infoTcs,
} from 'utils/utils.jsx';
import { DashboardContext } from 'contexts/DashboardContext';

const QuoteContext = React.createContext();

class QuoteContextProvider extends Component {
  static contextType = DashboardContext;

  constructor(props) {
    super(props);

    let today = new Date();
    let newdue_date = new Date();
    let due_date = new Date(newdue_date.setDate(newdue_date.getDate() + paymentterms));

    this.state = {
      loading: true,
      saving: false,
      paymentMethods: [],
      bankAccounts: [],
      selectedQuote: {
        items: [],
        jobs: [],
        notes: [],
        customer: {},
        creator: {},
      },

      quote: {
        items: [],
        jobs: [],
        notes: [],
        invoices: [],
        customer: {},
        creator: {},
      },

      invoice: {
        amount: 0,
        raised_date: today,
        due_date: due_date,
        tcs: infoTcs({ quote: { type: 'job' } }),
        paid_date: null,
        payments: [],
      },

      invoices: [],

      quotes: [],
      creditNotes: [],
      nextInvoicesPage: 1,
      loadingMore: false,

      copyLink: this.copyLink,

      getQuotes: this.getQuotes,

      getPublicInvoice: this.getPublicInvoice,
      acceptQuote: this.acceptQuote,
      emailInvoice: this.emailInvoice,
      // deleteQuote: this.deleteQuote,
      clearInvoiceDate: this.clearInvoiceDate,
      cancelQuote: this.cancelQuote,
      addEditQuote: this.addEditQuote,
      emailQuote: this.emailQuote,
      createInvoice: this.createInvoice,
      updateInvoice: this.updateInvoice,
      updateLoadedInvoiceData: this.updateLoadedInvoiceData,
      createUpdateInvoice: this.createUpdateInvoice,
      addInvoicePayment: this.addInvoicePayment,
      setInvoicequote: this.setInvoicequote,
      getInvoices: this.getInvoices,
      getRelatedInvoices: this.getRelatedInvoices,
      getInvoice: this.getInvoice,
      setInvoice: this.setInvoice,
      getPaymentMethods: this.getPaymentMethods,

      getQuote: this.getQuote,
      setQuote: this.setQuote,

      getCreditNotes: this.getCreditNotes,
      getCreditNote: this.getCreditNote,

      createCreditNote: this.createCreditNote,
      updateCreditNote: this.updateCreditNote,
      voidCreditNote: this.voidCreditNote,
      getTaxAndTotal: this.getTaxAndTotal,

      ledgerAccounts: this.ledgerAccounts,
      taxRates: this.taxRates,
      submitSurvey: this.submitSurvey,
      voidInvoice: this.voidInvoice,
      sort: this.sort,
      sortInvoices: this.sortInvoices,
      refreshdashboard: this.refreshDashboard,
    };
  }

  copyLink = (id) => {
    navigator.clipboard.writeText(`${window.location.origin}/quote?id=${id}`);
    toast.success('Link Copied!');
  };

  refreshDashboard = async () => {
    this.context.loadSideBar();
  };

  setInvoice = async (invoice) => {
    this.setState({ ...this.state, invoice });
  };

  setInvoicequote = async (invoicequote) => {
    this.setState({ ...this.state, quote: invoicequote });
  };

  getPaymentMethods = async () => {
    let bankAccounts = this.state.bankAccounts || [];
    let paymentMethods = this.state.paymentMethods || [];

    if (this.state.paymentMethods.length <= 0) {
      let req = await fetch(`/api/accounting/invoice/get_payment_info`);

      let res = await req.json();
      //console.log("got payment methods: ", res);
      let bankAccounts = res.accounts;
      let paymentMethods = res.methods;

      this.setState({ ...this.state, paymentMethods, bankAccounts });
      return { paymentMethods, bankAccounts };
    } else {
      return { paymentMethods, bankAccounts };
    }
  };

  getCreditNote = async (id) => {
    let toastID = toast.loading(`Loading credit note data...`);
    let req = await fetch(`/api/credit_note/${id}`);
    let res = await req.json();
    res._id
      ? toast.dismiss(toastID)
      : toast.update(toastID, {
          ...toastOptions,
          render: 'Error loading selected creditNote',
          type: 'error',
        });

    return res;
  };

  getCreditNotes = (more = false) => {
    console.log('getCreditNotes');
    this.setState({
      ...this.state,
      loading: this.state.creditNotes.length <= 0,
      loadingMore: more,
    });
    fetch(`/api/credit_notes/list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ next: this.state.nextPage }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toast.error(res.error, toastOptions);
          this.api_error();
        } else {
          let creditNotes = [...this.state.creditNotes, ...(res.creditNotes || [])];

          if (window.location.pathname.startsWith('/credit_notes')) {
            this.setState({
              ...this.state,
              creditNotes,
              nextPage: res.next,
              loading: false,
              loadingMore: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ ...this.state, loading: false, loadingMore: false });
        this.api_error();
        console.log(err);
      });
  };

  getTaxAndTotal = (item) => {
    let percentage =
      (toNumber(this.taxRates().find((r) => r.id == item.tax_rate_id)?.percentage) || 0) / 100;
    let VAT = toNumber(
      toNumber((toNumber(item.unit_price) || 0) * (toNumber(item.quantity) || 1)) * percentage,
    );
    let itemTotal = toNumber(
      toNumber((toNumber(item.unit_price) || 0) * (toNumber(item.quantity) || 1)) +
        toNumber(VAT) -
        toNumber(item.discount_amount || 0),
    );
    return { tax: VAT, total: itemTotal };
  };

  createCreditNote = async (state) => {
    let toastID = toast.loading('Please wait...');
    let creditnote = state.creditnote;
    console.log('creating creditnote: ', creditnote);

    let quote = state.quote;

    let payments = quote.total_paid;
    let total_credited = parseFloat(quote.total_credited);
    let thiscreditnotetotal = parseFloat(
      creditnote.credit_note_lines.length > 0
        ? creditnote.credit_note_lines.reduce(
            (total, item) => total + this.getTaxAndTotal(item).total,
            0,
          )
        : 0,
    );

    console.log(total_credited + '  + ' + thiscreditnotetotal + ' > ' + payments);
    if (total_credited + thiscreditnotetotal > payments) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'Credit Notes are greater than Payments',
        type: 'error',
      });

      return {};
    }

    try {
      let req = await fetch(`/api/credit_note/create`, {
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();

      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      // console.log(res)
      if (res.res != 'success') {
        return res;
      }

      let quotes = this.state.quotes.map((quote) => {
        if (quote._id == res.quote._id) {
          return res.quote;
        } else {
          return quote;
        }
      });

      this.setState({
        ...this.state,
        quotes,
        quote: res.quote,
        creditNotes: { ...this.state.creditNotes.push(res.creditNote) },
      });
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error with the action.',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  updateCreditNote = async (state) => {
    let toastID = toast.loading('Please wait...');
    let creditnote = state.creditnote;
    let quote = state.quote;
    console.log('updating creditnote: ', creditnote);
    let payments = quote.total_paid;
    let total_credited = quote.creditnotes.reduce(
      (total, creditnote) =>
        (total +=
          creditnote._id !== state.creditnote._id
            ? creditnote.credit_note_lines.reduce((total, item) => total + item.total_amount, 0)
            : 0),
      0,
    );

    creditnote.credit_note_lines.reduce(
      (total, item) => total + this.getTaxAndTotal(item).total,
      0,
    );

    // let total_credited1 = quote.creditnotes.reduce((total, item) => (

    // 	return (creditnote._id !== state.creditnote._id) ?
    // 	creditnote.credit_note_lines.reduce((total, item) => (total + this.getTaxAndTotal(item).total), 0)
    // 	: 0

    // ),0);

    console.log('total_credited: ', total_credited);

    let thiscreditnotetotal =
      creditnote.credit_note_lines.length > 0
        ? creditnote.credit_note_lines.reduce(
            (total, item) => total + this.getTaxAndTotal(item).total,
            0,
          )
        : 0;

    console.log(total_credited + '  + ' + thiscreditnotetotal + ' > ' + payments);
    if (total_credited + thiscreditnotetotal > payments) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'Credit Notes are greater than Payments',
        type: 'error',
      });

      return {};
    }

    try {
      let req = await fetch(`/api/credit_note/update`, {
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();

      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });

      if (res.res == 'success') {
        let creditNotes = this.state.creditNotes.map((creditnote) => {
          if (creditnote._id == res.creditNote._id) {
            return res.creditNote;
          } else {
            return creditnote;
          }
        });
        let quotes = this.state.quotes.map((quote) => {
          if (quote._id == res.quote._id) {
            return res.quote;
          } else {
            return quote;
          }
        });
        this.setState({ ...this.state, creditNotes, quotes, quote: res.quote });
      }
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error with the action.',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  voidCreditNote = async (state) => {
    let creditnote = state.creditnote;

    let toastID = toast.loading('Voiding Credit Note...');
    try {
      let req = await fetch(`/api/credit_note/void`, {
        method: 'POST',
        body: JSON.stringify({ creditnote }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();

      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      if (res.creditnote._id) {
        let creditNotes = this.state.creditNotes.map((creditNote) =>
          creditNote._id !== creditnote._id ? creditNote : res.creditnote,
        );
        this.setState({ ...this.state, creditNotes });
      }
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error voiding this credit note',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  ledgerAccounts = () => {
    return [
      {
        id: '0bec6590b99e11ecb2850279d0cc53c5',
        displayed_as: 'Sales - Products (4000)',
        $path: '/ledger_accounts/0bec6590b99e11ecb2850279d0cc53c5',
      },
      {
        id: '0becaa51b99e11ecb2850279d0cc53c5',
        displayed_as: 'Sales - Services (4010)',
        $path: '/ledger_accounts/0becaa51b99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bece4deb99e11ecb2850279d0cc53c5',
        displayed_as: 'Sales Discounts (4020)',
        $path: '/ledger_accounts/0bece4deb99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bed0af6b99e11ecb2850279d0cc53c5',
        displayed_as: 'Sale of Assets (4200)',
        $path: '/ledger_accounts/0bed0af6b99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bed3326b99e11ecb2850279d0cc53c5',
        displayed_as: 'Late Payment Charges (4400)',
        $path: '/ledger_accounts/0bed3326b99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bed6eb3b99e11ecb2850279d0cc53c5',
        displayed_as: 'Other income (4900)',
        $path: '/ledger_accounts/0bed6eb3b99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bed9dfdb99e11ecb2850279d0cc53c5',
        displayed_as: 'Carriage on Sales (4910)',
        $path: '/ledger_accounts/0bed9dfdb99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bf53b0cb99e11ecb2850279d0cc53c5',
        displayed_as: 'Royalties & Commissions Received (4920)',
        $path: '/ledger_accounts/0bf53b0cb99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bf55edab99e11ecb2850279d0cc53c5',
        displayed_as: 'Insurance Claims (4930)',
        $path: '/ledger_accounts/0bf55edab99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bf5848bb99e11ecb2850279d0cc53c5',
        displayed_as: 'Rental Income (4940)',
        $path: '/ledger_accounts/0bf5848bb99e11ecb2850279d0cc53c5',
      },
      {
        id: '0bfd8b5cb99e11ecb2850279d0cc53c5',
        displayed_as: 'Stock Adjustments (8250)',
        $path: '/ledger_accounts/0bfd8b5cb99e11ecb2850279d0cc53c5',
      },
    ];
  };

  taxRates = () => {
    return [
      {
        id: 'GB_STANDARD',
        displayed_as: 'Standard 20.00%',
        $path: '/tax_rates/GB_STANDARD',
        name: 'STANDARD',
        percentage: '20.0',
      },
      {
        id: 'GB_LOWER',
        displayed_as: 'Lower Rate 5.00%',
        $path: '/tax_rates/GB_LOWER',
        name: 'LOWER',
        percentage: '5.0',
      },
      {
        id: 'GB_ZERO',
        displayed_as: 'Zero Rated 0.00%',
        $path: '/tax_rates/GB_ZERO',
        name: 'ZERO',
        percentage: '0.0',
      },
      {
        id: 'GB_EXEMPT',
        displayed_as: 'Exempt 0.00%',
        $path: '/tax_rates/GB_EXEMPT',
        name: 'EXEMPT',
        percentage: '0.0',
      },
      {
        id: 'GB_NO_TAX',
        displayed_as: 'No VAT',
        $path: '/tax_rates/GB_NO_TAX',
        name: 'NO_TAX',
        percentage: '0.0',
      },
    ];
  };

  submitSurvey = async (questions) => {
    try {
      let req = await fetch(`/api/submitquotesurvey`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quote: this.state.quote._id,
          questions,
        }),
      });
      let res = await req.json();
      console.log('res: ', res);
      if (res.res === 'success') {
        this.setState({
          ...this.state,
          quote: { ...this.state.quote, quote_survey_submitted: new Date() },
        });

        return res.quote;
      } else {
        return res.quote;
      }
    } catch (err) {
      console.log('err', err);
      return res.quote;
    }
  };

  emailQuote = async (quote) => {
    let toastID = toast.loading(`Sending Email...`);
    if (!quote?.customer?.email && !quote?.customer?.mobile) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'Customer has no email address or mobile number',
        type: 'error',
      });
      return;
    }

    //this.setState({...this.state, saving: true})
    try {
      let req = await fetch(`/api/quote/email`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          recipient: quote.customer.email,
          recipient2: quote.customer.alternativeEmail,
          mobile: quote.customer.mobile,
          subject: '',
          body: '',
          quote: quote._id,
        }),
      });
      let res = await req.json();
      if (res.res === 'success') {
        this.setState({ ...this.state, quote: res.quote });
        toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
        return res.quote;
      } else {
        toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
        return res.quote;
      }
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error sending the email',
        type: 'error',
      });
      console.log('err', err);
      return res.quote;
    }
  };

  getInvoice = async (id) => {
    let toastID = toast.loading(`Loading invoice data for id ${id}...`);
    let req = await fetch(`/api/invoice/${id}`);
    let res = await req.json();

    res.invoice._id
      ? toast.dismiss(toastID)
      : toast.update(toastID, {
          ...toastOptions,
          render: 'Error loading selected invoice',
          type: 'error',
        });

    // if (this.state.paymentMethods.length <= 0) {
    // 	await this.getPaymentMethods();
    // }
    let thisinvoice = res.invoice;

    return { invoice: thisinvoice };
  };

  getPublicInvoice = async (id) => {
    console.log('getting invoice ' + id);
    let req = await fetch(`/api/publicinvoice/${id}`);
    let res = await req.json();
    console.log('got inv:', res.invoice);
    return res.invoice;
  };

  emailInvoice = async (invoice) => {
    let toastID = toast.loading(`Sending Email...`);
    if (!invoice?.customer?.email) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'Customer has no email address',
        type: 'error',
      });
      return;
    }

    this.setState({ ...this.state, saving: true });
    try {
      let req = await fetch(`/api/accounting/invoice/email`, {
        method: 'POST',
        body: JSON.stringify(invoice),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let res = await req.json();

      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      if (res.res == 'success') {
        // TODO - update last sent date for invoice - let quotes = this.state.quotes
        let quoteid = invoice.quote._id;
        let invoiceid = invoice._id;

        let quotes = this.state.quotes.map((quote) => {
          if (quote._id == quoteid) {
            let invoices = quote.invoices.map((invoice) => {
              if (invoice._id == invoiceid) {
                return { ...invoice, email_date: new Date() };
              } else {
                return invoice;
              }
            });

            return { ...quote, invoices };
          } else {
            return quote;
          }
        });

        this.setState({ ...this.state, quotes });
      }
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error sending the email',
        type: 'error',
      });
      console.log(err);
    }

    this.setState({ ...this.state, saving: false });
  };

  api_error = () => {
    if (window.confirm('There were some error loading this page. Please refresh')) {
      window.location.reload();
    }
  };

  getQuotes = async (type) => {
    this.setState({ ...this.state, loading: true, nextInvoicesPage: 1, loadingMore: false });
    let req = await fetch(`/api/quote/list`, {
      method: 'POST',
      body: JSON.stringify(type),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let res = await req.json();
    if (res.error) {
      toast.error(res.error, toastOptions);
      this.api_error();
      return {};
    } else {
      //let quotes = res.quotes?.map(this.adjustQuoteFields);
      this.setState({ ...this.state, quotes: res.quotes });
      return res.quotes;
    }
  };

  getRelatedInvoices = async (quote) => {
    let invoices = [];

    fetch(`/api/quotes/related_invoices/${quote._id}`)
      .then((res) => res.json())
      .then((invoices) => {
        console.log('context got invoices: ', invoices);
        this.setState({ ...this.state, invoices });
      })
      .catch((e) => {
        console.log('error getting invoices for:', this.props.quote._id, 'error:', e);
        invoices = invoices;
      });

    return invoices;
  };

  getInvoices = async (quoteid = '', firstLoad = false) => {
    let req = await fetch(`/api/quote/list`, {
      method: 'POST',
      body: JSON.stringify({ quoteid, type: 'invoice', next: this.state.nextInvoicesPage || 1 }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let res = await req.json();

    if (res.error) {
      toast.error(res.error, toastOptions);
      this.api_error();
      return {};
    } else {
      let urlParams = new URLSearchParams(res?.next?.replace('/sales_invoices?', ''));
      let nextInvoicesPage = urlParams.get('page');
      let invoices = res.invoices;

      invoices = firstLoad ? invoices : [...this.state.invoices, ...invoices];
      if (quoteid) {
        this.setState({
          ...this.state,
          quote: { ...this.state.quote, invoices },
          nextInvoicesPage,
          loading: false,
          loadingMore: false,
        });
      } else {
        this.setState({
          ...this.state,
          invoices,
          nextInvoicesPage,
          loading: false,
          loadingMore: false,
        });
      }
      return res.invoices;
    }
  };

  adjustQuoteFields = (quote) => {
    quote.createdAt = <small>{tillTimeString(quote.createdAt)}</small>;
    quote.lastUpdated = <small>{tillTimeString(quote.updatedAt)}</small>;
    quote.availableInStock = quote.outOfStock ? 'No' : 'Yes';
    quote.totalItems = quote.items?.length;

    quote.totalJobs = quote.jobs?.length;
    quote.customer = quote.customer[0]
      ? quote.customer[0]
      : quote.customer
      ? quote.customer
      : { fname: 'none', lname: 'none' };
    quote.customerName = quote.customer?.fname; /*  + ' ' + quote.customer?.lname */
    quote.creator = quote.creator[0]
      ? quote.creator[0]
      : quote.creator
      ? quote.creator
      : { fname: 'none', lname: 'none' };
    quote.creatorName = quote.creator?.fname;

    return quote;
  };

  addEditQuote = async (quote) => {
    let thisquote = { ...quote };

    let itemsTotal = thisquote.items.reduce(
      (total, item) => total + Number(item.net_amount + item.tax_amount),
      0,
    );
    let quotePriceTotal = thisquote.altPrice ? thisquote.altPrice : itemsTotal;

    thisquote.itemsTotal = itemsTotal;
    thisquote.quotePriceTotal = quotePriceTotal;
    let toastID = toast.loading(
      `${thisquote._id ? 'Updating' : 'Creating'} ${thisquote.type == 'job' ? 'Job' : 'Quote'} ...`,
    );
    if (!quote.customer || quote.customer == '') {
      toast.update(toastID, { ...toastOptions, render: 'Please select a customer', type: 'error' });
      return;
    }
    this.setState({ ...this.state, saving: true });

    try {
      let req = await fetch(`/api/quote/manage`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(thisquote),
      });
      let res = await req.json();

      let quotes = this.state.quotes;
      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      if (res.res == 'success') {
        this.context.loadSideBar();
        if (quote._id) {
          // Update the updated quote in the loacal array as well instead of fetching all quotes again.
          quotes = quotes.map((q) =>
            res.quote?._id == q._id ? this.adjustQuoteFields(res.quote) : q,
          );
        } else {
          quotes.push(this.adjustQuoteFields(res.quote));
        }
      }
      this.setState({ ...this.state, quote: res.quote, quotes, saving: false });

      return res;
    } catch (err) {
      this.setState({ ...this.state, saving: false });
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some issues creating this quote',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return { ...quote, error: 'error' };
    }
  };

  setQuote = async (quote) => {
    this.setState({ ...this.state, quote });
  };

  getQuote = async (cid) => {
    console.log('getting quote for: ' + cid);
    if (cid == 0) {
      this.setState({ ...this.state, quote: {} });
      return {};
    } else {
      try {
        let req = await fetch(`/api/getQuote/${cid}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        let res = await req.json();

        if (res.res == 'success') {
          console.log('got quote: ', res.quote);
          this.setState({ ...this.state, quote: res.quote });
          return res.quote;
        }

        return res;
      } catch (err) {
        console.log(err);
        return {};
      }
    }
  };

  acceptQuote = async (cid) => {
    // console.log(data)
    let toastID = toast.loading('Please wait...');
    try {
      let req = await fetch(`/api/accept_quote/${cid}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();
      console.log('acceptedquote?', res);
      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });

      if (res.res == 'success') {
        this.context.loadSideBar();
        this.setState({ ...this.state, quote: res.quote });
      }

      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error with the action.',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  deleteQuote = async (cid) => {
    let toastID = toast.loading('Deleting Quote...');
    try {
      let req = await fetch(`/api/quote/${cid}`, {
        method: 'DELETE',
      });
      let res = await req.json();
      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      let quotes = this.state.quotes.filter((quote) => quote._id !== cid);
      this.setState({ ...this.state, quotes });
      this.context.loadSideBar();
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error deleting this quote',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  cancelQuote = async (cid) => {
    let toastID = toast.loading('Please wait...');
    try {
      let req = await fetch(`/api/quote/${cid}`, {
        method: 'DELETE',
      });
      let res = await req.json();
      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      let quotes = this.state.quotes;
      quotes.forEach((quote) => {
        if (quote._id == cid) {
          quote.status = 'cancelled';
          this.context.loadSideBar();
        }
      });
      this.setState({ ...this.state, quotes });
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were some error with the action.',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  sort = (sortKey, desc) => {
    let asc;

    let quotes = this.state.quotes;
    sortKey = sortKey == 'QuotePriceTotal' ? 'quotePriceTotal' : sortKey;
    sortKey = sortKey == 'createdDate' ? 'createdAt' : sortKey;
    sortKey = sortKey == 'updatedDate' ? 'createdAt' : sortKey;
    sortKey = sortKey == 'customerName' ? 'customer.lname' : sortKey;

    if (sortKey == 'customer.lname') {
      if (desc) {
        desc = 1;
        asc = -1;
      } else {
        desc = -1;
        asc = 1;
      }
      quotes.sort((a, b) =>
        a.customer.lname > b.customer.lname ? desc : b.customer.lname > a.customer.lname ? asc : 0,
      );
    } else {
      sort(quotes, sortKey, desc);
    }

    this.setState({ ...this.state, quotes });
  };

  sortInvoices = (sortKey, desc) => {
    let asc;

    let invoices = this.state.invoices;
    sortKey = sortKey == 'QuotePriceTotal' ? 'quotePriceTotal' : sortKey;
    sortKey = sortKey == 'createdDate' ? 'createdAt' : sortKey;
    sortKey = sortKey == 'updatedDate' ? 'createdAt' : sortKey;
    sortKey = sortKey == 'customerName' ? 'customer.lname' : sortKey;

    if (sortKey == 'customer.lname') {
      if (desc) {
        desc = 1;
        asc = -1;
      } else {
        desc = -1;
        asc = 1;
      }
      invoices.sort((a, b) =>
        a.customer.lname > b.customer.lname ? desc : b.customer.lname > a.customer.lname ? asc : 0,
      );
    } else if (sortKey == 'emailed_date') {
      if (desc) {
        desc = 1;
        asc = -1;
      } else {
        desc = -1;
        asc = 1;
      }

      let emailed = invoices.filter((invoice) => invoice.emailed_date);
      let notEmailed = invoices.filter((invoice) => !invoice.emailed_date);

      emailed.sort((a, b) =>
        a.emailed_date > b.emailed_date ? desc : b.emailed_date > a.emailed_date ? asc : 0,
      );

      invoices = [...emailed, ...notEmailed];
    } else if (sortKey == 'viewed_date') {
      if (desc) {
        desc = 1;
        asc = -1;
      } else {
        desc = -1;
        asc = 1;
      }

      let viewed = invoices.filter((invoice) => invoice.viewed_date);
      let notViewed = invoices.filter((invoice) => !invoice.viewed_date);

      viewed.sort((a, b) =>
        a.viewed_date > b.viewed_date ? desc : b.viewed_date > a.viewed_date ? asc : 0,
      );

      invoices = [...viewed, ...notViewed];
    } else {
      sort(invoices, sortKey, desc);
    }

    console.log(sortKey, desc);

    this.setState({ ...this.state, invoices });
  };

  addInvoicePayment = async (data) => {
    let toastID = toast.loading('Adding Payment...');
    data.invoice = this.addInvoicePrices(data.invoice);

    let req = await fetch('/api/accounting/payments/create', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let res = await req.json();
    console.log('res: ', res);
    if (res.res == 'success') {
      toast.update(toastID, {
        ...toastOptions,
        render: 'Success syncing payment to sage',
        type: 'success',
      });
      this.setState({ ...this.state, invoice: data.invoice, quote: res.quote });
    } else {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There was a problem recording this payment: ' + res.msg,
        type: 'error',
      });
    }

    return res;
  };

  addInvoicePrices(invoice) {
    let thisInvoicedata = invoice;

    console.log('addInvoicePrices: ', thisInvoicedata);

    thisInvoicedata.invoice_lines_count = thisInvoicedata.invoice_lines?.length || 0;
    let update = thisInvoicedata._id ? true : false;

    let newnet_amount = toNumber(
      thisInvoicedata.invoice_lines.reduce(
        (total, item) => (item.net_amount ? (total += toNumber(item.net_amount)) : total),
        0,
      ),
    );
    let newtax_amount = toNumber(
      thisInvoicedata.invoice_lines.reduce(
        (totalvat, item) => (item.tax_amount ? (totalvat += toNumber(item.tax_amount)) : totalvat),
        0,
      ),
    );

    let newtotalamount = toNumber(newnet_amount) + toNumber(newtax_amount);

    thisInvoicedata.tax_amount = newtax_amount;
    thisInvoicedata.net_amount = newnet_amount;
    thisInvoicedata.total_amount = newtotalamount;
    thisInvoicedata.taxrateid = process.env.REACT_APP_TAX_RATE_ID;
    thisInvoicedata.ledger = process.env.REACT_APP_SALES_LEDGER_ACCOUNT;

    console.log('thisInvoicedata: ', thisInvoicedata);

    return thisInvoicedata;
  }

  createUpdateInvoice = async (data) => {
    let thisInvoiceData = data.invoice ? data.invoice : data;

    thisInvoiceData = this.addInvoicePrices(thisInvoiceData);

    // thisInvoicedata.invoice_lines_count = thisInvoicedata.invoice_lines?.length || 0;
    let update = thisInvoiceData._id ? true : false;

    let url = update ? '/api/accounting/invoice/update' : '/api/accounting/invoice/create';

    let toastID = toast.loading('Please wait...');

    let req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(thisInvoiceData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let res = await req.json();

    let invoice = res.invoice;
    toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });

    if (invoice) {
      let quoteid = invoice.quote._id;
      let thisquote = this.state.quote;

      let invoices = this.state.invoices;

      // put this quote in the state quotes list
      let quotes = this.state.quotes.map((statequote) => {
        if (statequote._id == invoice.quote._id) {
          //statequote.invoices.push(invoice)
          return invoice.quote;
        } else {
          return statequote;
        }
      });

      if (update) {
        invoices = this.state.invoices.map((stateinvoice) => {
          if (stateinvoice._id == invoice._id) {
            return invoice;
          } else {
            return stateinvoice;
          }
        });
        let quoteinvoices = this.state.quote.invoices.map((quoteinvoice) => {
          if (quoteinvoice._id == invoice._id) {
            return invoice;
          } else {
            return quoteinvoice;
          }
        });
        console.log('updating: ', invoices, quoteinvoices);
        thisquote.invoices = quoteinvoices;
      } else {
        invoices = this.state.invoices;
        invoices.push(invoice);

        thisquote.invoices.push(invoice);
      }

      this.context.loadSideBar();
      this.setState({ ...this.state, invoices, quotes, quote: thisquote });
    } else {
      console.log('no invoice returned');
    }

    return res;
  };

  voidInvoice = async (invoice) => {
    let toastID = toast.loading('Voiding Invoice...');
    try {
      let req = await fetch(`/api/accounting/invoice/void`, {
        method: 'POST',
        body: JSON.stringify({ invoice }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();

      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });

      let resinvoice = res.invoice;
      let resquote = resinvoice.quote;

      if (resinvoice._id) {
        this.context.loadSideBar();

        let quotes = this.state.quotes.map((quote) =>
          quote._id == resquote._id ? thisquote : quote,
        );
        this.setState({ ...this.state, quotes, quote: resquote, invoice: resinvoice });
      }

      return resinvoice;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There were an error voiding this invoice',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  markInvoiceAsPaid = async (id, reason) => {
    let toastID = toast.loading('Marking Invoice As Paid...');
    try {
      let req = await fetch(`/api/accounting/invoice/paid`, {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      let res = await req.json();
      toast.update(toastID, { ...toastOptions, render: res.text, type: res.res });
      if (res.invoice.id) {
        this.context.loadSideBar();
        let quotes = this.state.quotes.map((invoice) =>
          invoice.id !== id ? invoice : res.invoice,
        );
        this.setState({ ...this.state, quotes });
      }
      return res;
    } catch (err) {
      toast.update(toastID, {
        ...toastOptions,
        render: 'There was an error saving this invoice',
        type: 'error',
      });
      this.api_error();
      console.log(err);
      return {};
    }
  };

  updateLoadedInvoiceData = (data) => {
    let quotes = this.state.quotes.map((quote) => {
      if (quote.sageID == data.id) {
        quote.total_paid = data.total_paid;
        quote.status = data.status.id;
        return quote;
      } else {
        return quote;
      }
    });
    this.setState({ ...this.state, quotes });
  };

  render() {
    return <QuoteContext.Provider value={this.state}>{this.props.children}</QuoteContext.Provider>;
  }
}

export { QuoteContext, QuoteContextProvider };
