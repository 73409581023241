import React, { Component } from "react";
import { QuoteContext } from "contexts/QuoteContext";
// import { FiUser, FiLock } from 'react-icons/fi';
// import { Navigate } from 'react-router-dom';
import {
  AiFillSave,
  AiOutlineDelete,
  AiFillPrinter,
  AiOutlineEdit,
} from "react-icons/ai";
import {
  infoData,
  getQuoteDetails,
  infoItems,
  infoTcs,
  infocustomeradd,
} from "utils/utils";
import logo from "assets/logo.png";
import "./Quote.scss";
import BarWave from "react-cssfx-loading/lib/BarWave";
import PrintQuote from "components/PrintQuote/PrintQuote";
import ReactToPrint from "react-to-print";

export default class Quote extends Component {
  static contextType = QuoteContext;

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      loading: false,
      load: true,
    };
  }

  acceptQuote = async (e) => {
    this.setState({ ...this.state, disabled: true });
    let res = await this.context.acceptQuote(this.state.quote._id);
    this.setState({ ...this.state, quote: res.quote, disabled: false });
    window.scrollTo(0, 0);
  };

  loadQuoteDetails = async (quoteid) => {
    if (!this.state.loading && this.state.load) {
      this.setState({ ...this.state, load: false, loading: true });
      let quote = await this.context.getQuote(quoteid);

      this.setState({ ...this.state, quote, loading: false });
    }
  };

  componentDidMount() {
    //const quoteid = this.props.match.params.quoteid
    let quoteid = new URLSearchParams(window.location.search).get("id");
    this.loadQuoteDetails(quoteid);
  }

  render() {
    let quote = this.state.quote;
    if (this.state.loading)
      return (
        <div className="pagePreloader">
          <span>
            <BarWave width="50px" height="20px" />
            <br />
            loading...
          </span>
        </div>
      );
    if (!quote || !quote._id)
      return (
        <div className="pagePreloader">
          <h3>Not found or broken link</h3>
        </div>
      );

    if ("cancelled" === quote.status)
      return (
        <div className="pagePreloader">
          <h3>Sorry, The quote was either cancelled or closed.</h3>
        </div>
      );

    return (
      <div className="quote_details_page">
        {quote.accepted_date && (
          <div className="pagePreloader-half">
            <center>
              <h3>
                Thank You! You have successfully accepted your quote. <br />
                We'll get in touch soon.
              </h3>
            </center>
          </div>
        )}
        {!quote.accepted_date && (
          <div className="content_container">
            {!quote.sageID && !quote.accepted_date && (
              <div className="approve_btns">
                By Clicking Agree you confirm that you have read and agree to
                our Terms and Conditions and wish to proceed with this order
                <br />
                <button
                  onClick={this.acceptQuote}
                  disabled={this.state.disabled}
                >
                  Agree & Place This Order
                </button>
              </div>
            )}
          </div>
        )}
        <PrintQuote ref={(el) => (this.componentRef = el)} quote={quote} />
        <div className="content_container">
          <ReactToPrint
            trigger={() => {
              return (
                <button type="button">
                  <AiFillPrinter />
                  Print
                </button>
              );
            }}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}
